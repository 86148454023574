import React from 'react';
import PropTypes from 'prop-types';

import appStoreDeu from '../../images/appstore-de.png';
import appStoreSwe from '../../images/appstore.png';
import googlePlaySwe from '../../images/googleplay.png';
import googlePlayLightSwe from '../../images/google.svg';
import appStoreLightSwe from '../../images/appstore.svg';

const appStore = {
  DEU: appStoreDeu,
  SWE: appStoreSwe,
}

const appStoreLight = {
  SWE: appStoreLightSwe,
}

const googlePlayLight = {
  SWE: googlePlayLightSwe,
}

const googlePlay = {
  DEU: googlePlaySwe,
  SWE: googlePlaySwe,
}

const CONFIG = {
  APPLE: {
    alt: 'App Store logo',
    href: 'https://itunes.apple.com/se/app/id1036395238',
    imageIdSuffix: '-app-store-image',
    linkIdSuffix: '-app-store-link',
    src: appStore,
  },
  GOOGLE: {
    alt: 'Google Play logo',
    href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
    imageIdSuffix: '-google-play-image',
    linkIdSuffix: '-google-play-link',
    src: googlePlay,
  },
  APPLE_LIGHT: {
    alt: 'App Store logo',
    href: 'https://itunes.apple.com/se/app/id1036395238',
    imageIdSuffix: '-app-store-image',
    linkIdSuffix: '-app-store-link',
    src: appStoreLight,
  },
  GOOGLE_LIGHT: {
    alt: 'Google Play logo',
    href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
    imageIdSuffix: '-google-play-image',
    linkIdSuffix: '-google-play-link',
    src: googlePlayLight,
  },
};

const StoreLogo = ({ className, lang, trackerName, type }) => {
  return (
    <a
      id={`${trackerName}${CONFIG[type].linkIdSuffix}`}
      href={CONFIG[type].href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        alt={CONFIG[type].alt}
        className={className}
        id={`${trackerName}${CONFIG[type].imageIdSuffix}`}
        src={CONFIG[type].src[lang]}
      />
    </a>
  );
};

StoreLogo.TYPES = {
  APPLE: 'APPLE',
  APPLE_LIGHT: 'APPLE_LIGHT',
  GOOGLE: 'GOOGLE',
  GOOGLE_LIGHT: 'GOOGLE_LIGHT'
};

StoreLogo.LANG = {
  DEU: 'DEU',
  SWE: 'SWE',
};

StoreLogo.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.oneOf(Object.keys(StoreLogo.LANG)),
  trackerName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
};

StoreLogo.defaultProps = {
  lang: StoreLogo.LANG.SWE
}

export default StoreLogo;
