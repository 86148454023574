import React from "react";
import Modal from "react-bootstrap/Modal";

import { ButtonOld } from "../";

const BaseModal = ({ children, show, title, waiting, onClose, onSave, disabled }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <ButtonOld disabled={waiting} variant="secondary" onClick={onClose}>
          Stäng
        </ButtonOld>
        <ButtonOld disabled={disabled ? disabled : waiting} variant="primary" onClick={onSave}>
          Spara
        </ButtonOld>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal;
