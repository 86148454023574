/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
      }
    }
  }
`;
function SEO({ description, lang, meta, title, ogImage, ogTitle, redirect }) {
  const { pathname, search } = useLocation();
  const { site } = useStaticQuery(query);

  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;

  const metaDescription = description || defaultDescription;

  React.useEffect(() => {
    if (redirect) {
      window.location = `${redirect}${search}`;
    }
  }, [redirect, search]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        { name: `description`, content: `${metaDescription}` },
        { property: `og:title`, content: ogTitle || defaultTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
        { property: 'og:site_name', content: 'Lylli' },
        { property: 'og:locale', content: 'sv_SE' },
        // { property: 'og:image', content: ogImage.url },
        // { property: 'og:image:secure_url', content: ogImage.url },
        // { property: 'og:image:type', content: `image/${ogImage.type}` },
        // { property: 'og:image:width', content: `${ogImage.width}` },
        // { property: 'og:image:height', content: `${ogImage.height}` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: 'Lylli' },
        { name: `twitter:description`, content: metaDescription },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap" rel="stylesheet" />
      <link href="https://files.lylli.se/icons/lylli-favicon-32.png" rel="shortcut icon" type="image/x-icon" />
      <link href="https://files.lylli.se/icons/lylli-favicon.png" rel="apple-touch-icon" />
      <script>
        {`    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2252143,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ogImage: PropTypes.shape({
    height: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['gif', 'jpeg', 'png']).isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }),
  ogTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  description: ``,
  lang: `sv`,
  meta: [],
  ogImage: {
    height: 1200,
    type: 'jpeg',
    // Image needs to be uploaded manually to FTP!
    url: 'https://boksnok.se/using-boksnok-2-wide.jpg',
    width: 1200,
  },
  ogTitle: 'Lylli',
};

export default SEO;
