import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import FooterColumn from './FooterColumn';

import './Footer.css';
import iconFB from '../../../images/icon-fb.png';
import iconIN from '../../../images/icon-in.png';
import StoreLogo from '../StoreLogo';

const TextLink = ({ children, to, external }) => (
  <p>
    <a href={external ? `${to}` : `https://www.lylli.se${to}`}>{children}</a>
  </p>
);

export default () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container className="footer-rows">
        <Row className="footer-row">
          <FooterColumn title="Lär känna oss">
            <TextLink to="/om-oss">Om oss</TextLink>
            <TextLink external to={`https://press.lylli.se`}>
              Press
            </TextLink>
            <TextLink to="/vanliga-fragor">Vanliga frågor</TextLink>
            <TextLink external to={`https://jobs.lylli.se/`}>
              Karriär
            </TextLink>
            <TextLink to="/allmanna-villkor">Allmänna villkor</TextLink>
            <TextLink to="/kakor">Kakor</TextLink>
            <TextLink to="/personuppgiftspolicy">Personuppgiftspolicy</TextLink>
          </FooterColumn>

          <FooterColumn title="Kom igång">
            <TextLink to="/konto/skapa-konto">Skapa konto</TextLink>
            <TextLink to="/laslust">Tips och inspiration</TextLink>
            <p className="stores">
              <StoreLogo trackerName="footer" type={StoreLogo.TYPES.APPLE} />
              <StoreLogo trackerName="footer" type={StoreLogo.TYPES.GOOGLE} />
            </p>
          </FooterColumn>

          <FooterColumn title="Följ oss">
            <p>
              <a href={`https://facebook.com/lylli.appen`} target="_blank" rel="noopener noreferrer">
                <img alt="Facebook logo" className="social-logo" src={iconFB} />
                Facebook
              </a>
            </p>
            <p>
              <a
                href={`https://www.instagram.com/lylli_se/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Instagram logo" className="social-logo" src={iconIN} />
                Instagram
              </a>
            </p>
            <p>
              &nbsp;
              <a href={`mailto:kontakt@lylli.se`} rel="noopener noreferrer" target="_blank">
                <span className="no-hover">@&nbsp;&nbsp;</span>Maila oss
              </a>
            </p>
          </FooterColumn>
        </Row>
      </Container>
    </footer>
  );
};
